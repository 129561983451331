import { messageCenterService } from '@/api';
import { Paging } from '@/api/base';
import { MessageStatusEnum } from '@/resource/enum';
import { MessageResource } from '@/resource/model';
import { MessageCenterModule } from '@/store/modules/message';
import { messageError } from '@/utils';
import { ElTabPane } from 'element-ui/types/tab-pane';
import { Component, Vue } from 'vue-property-decorator';
export type MessageTab = 'notice' | 'todo';

@Component({
  components: {}
})
export default class MessageCenter extends Vue {
  public activeTab: MessageTab = 'notice';

  public notices: Array<MessageResource> = [];

  public noticePaging: Paging = {
    showCount: 10,
    currentPage: 1
  };

  public noticeTotal = 0;

  public todos: Array<MessageResource> = [];

  public todoPaging: Paging = {
    showCount: 10,
    currentPage: 1
  };

  public todoTotal = 0;

  public created(): void {
    this.getNotices();
  }

  public getNotices(): void {
    messageCenterService
      .getNotices(this.noticePaging)
      .then(res => {
        this.notices = res.data;
        this.noticeTotal = res.total;
      })
      .catch(error => {
        messageError(error);
      });
  }

  public getTodos(): void {
    messageCenterService
      .getTodos(this.todoPaging)
      .then(res => {
        this.todos = res.data;
        this.todoTotal = res.total;
      })
      .catch(error => {
        messageError(error);
      });
  }

  public setReaded(message: MessageResource): void {
    if (message.status === MessageStatusEnum.readed) {
      return;
    }
    MessageCenterModule.SetReaded(message);
  }

  /**
   * 是否已读
   * @param status
   * @returns
   */
  public isReaded(status: MessageStatusEnum): boolean {
    return status === MessageStatusEnum.readed;
  }

  public handleTabClick(tab: ElTabPane): void {
    if (tab.name === 'todo') {
      this.getTodos();
      return;
    }
    if (tab.name === 'notice') {
      this.getNotices();
    }
  }
}
